import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { stripIds } from '../utils/utils';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // remove ids from url
    window.plausible('pageview', { u: `https://cyclequest.cc${stripIds(location.pathname)}` });
  }, [location]);
};

export default usePageTracking;
