import { Outlet, Link as RouterLink } from 'react-router-dom';
import { Stack, Box, Container, Link, Typography } from '@mui/material';

export default function MainLayout({ children }) {
  return (
    <>
      <div>
        {children}
      </div>

      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default'
        }}
      >
        <Container maxWidth="lg">
          <Stack direction="row" spacing={2} sx={{ justifyContent: "center" }}>
            <Typography variant="body2" paddingBottom={4} sx={{}}>
              <RouterLink to="/release-notes" style={{
                fontWeight: 500,
                color: 'grey',
                textDecoration: 'none',
              }}>
                Release Notes
              </RouterLink>
            </Typography>

            <Typography variant="body2" paddingBottom={4}>
              <RouterLink to="/terms" style={{ fontWeight: 500, color: 'grey', textDecoration: 'none' }}>
                Terms of Service
              </RouterLink>
            </Typography>

            <Typography variant="body2" paddingBottom={4}>
              <RouterLink to="/privacy" style={{ fontWeight: 500, color: 'grey', textDecoration: 'none' }}>
                Privacy Policy
              </RouterLink>
            </Typography>
          </Stack>

          <Typography variant="caption" component="p">
            © 2024, all rights reserved
            <br />
            <Link href="https://cyclequest.cc/">cyclequest.cc</Link>
          </Typography>
        </Container>
      </Box>
    </>
  );
}
